import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { getLoginUserInfo } from "./jwt.helper";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs";
import { getSellingPriceForHotel, hotelReview } from "./generic.helper";
import {
  LanguageTranslationConst,
  WebsiteConst,
} from "../constant/generic.const";
import { TranslateService } from "@ngx-translate/core";
import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: "root",
})
export class CommonFunction {
  langCode =
    environment.code == WebsiteConst.PAGOVOY
      ? LanguageTranslationConst.ESPANOL
      : LanguageTranslationConst.ENGLISH;
  code = sessionStorage.getItem("tripCode")
    ? "/" + sessionStorage.getItem("tripCode") + "/"
    : "/";
  constructor(
    private _location: Location,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService
  ) {
    // Author: xavier | 2021/8/27
    // Description: Set locale for date & time functions
    const lang = localStorage.getItem("_lang");

    if (lang != null) {
      let language = JSON.parse(lang);
      moment.locale(JSON.parse(lang).iso_1Code);
      this.langCode = language.iso_1Code;
    }

    translate.onLangChange.subscribe((ob) => {
      moment.locale(ob.lang);
    });
  }

  getSelectedLangCode() {
    const lang = localStorage.getItem("_lang");
    if (lang != null) {
      let language = JSON.parse(lang);
      return language.iso_1Code;
    } else {
      return environment.code == WebsiteConst.PAGOVOY
        ? LanguageTranslationConst.ESPANOL
        : LanguageTranslationConst.ENGLISH;
    }
  }

  setHeaders(params = null, isFake = true) {
    let reqData: any = { headers: {} };
    const accessToken = isFake
      ? localStorage.getItem("_lay_sess_fake") ||
        localStorage.getItem("_lay_sess")
      : localStorage.getItem("_lay_sess");

    if (accessToken) {
      reqData = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          referral_id: ``,
          currency: "USD",
          language: this.getSelectedLangCode(),
          source_code: this.getDomainName() || "LT",
        },
      };
    } else {
      reqData = {
        headers: {
          currency: "USD",
          language: this.getSelectedLangCode(),
          source_code: this.getDomainName() || "LT",
        },
      };
    }
    if (params) {
      Object.keys(params).forEach((k) => {
        reqData.headers[k] = params[k];
      });

      reqData.headers.referral_id = ``;
    }
    return reqData;
  }

  setMembershipHeaders(params = "") {
    const accessToken = localStorage.getItem("_lay_sess");
    const reqData = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        referral_id: "",
        language: this.getSelectedLangCode(),
        source_code: this.getDomainName() || "LT",
      },
    };
    if (params) {
      let reqParams = {};
      Object.keys(params).forEach((k) => {
        reqParams[k] = params[k];
      });
      reqData["params"] = reqParams;
    }
    return reqData;
  }

  setWithoutLoginHeader() {
    return {
      headers: {
        language: this.getSelectedLangCode(),
        // origin: environment.name || "laytrip",
        source_code: this.getDomainName() || "LT",
      },
    };
  }

  convertDateFormat(date, sourceFormat, languageCode = null) {
    if (languageCode == null) {
      return moment(date, sourceFormat).format("MMM DD, YYYY");
    }
    return date;
  }

  convertDateYYYYMMDD(date, sourceFormat) {
    return moment(date, sourceFormat).format("YYYY-MM-DD");
  }
  convertDateMMDDYYYY(date, sourceFormat) {
    return moment(date, sourceFormat).format("MM/DD/YYYY");
  }

  convertDateMMYY(date, sourceFormat) {
    return moment(date, sourceFormat).format("MM/YY");
  }

  decodeUrl(url) {
    let prevUrl = [];
    let queryParams = {};
    if (url) {
      prevUrl = url.split("?");
      if (typeof prevUrl[1] != "undefined") {
        let params = prevUrl[1].split("&");
        for (let i in params) {
          let param = params[i].split("=");
          queryParams[param[0]] = param[1];
        }
      }

      return {
        url: prevUrl[0],
        params: queryParams,
      };
    }
    return {
      url: "/",
      params: {},
    };
  }

  getUserCountry() {
    try {
      let countryCode = localStorage.getItem("__uorigin");
      return countryCode || "";
    } catch (e) {
      return "";
    }
  }

  goBack() {
    if (window.history.length > 1) {
      this._location.back();
    } else {
      this.router.navigate([this.code]);
    }
  }

  getGuestUser() {
    let userDetails = getLoginUserInfo();
    if (!userDetails.roleId || userDetails.roleId == 7) {
      let guestuserId = localStorage.getItem("__gst");
      if (guestuserId) {
        return guestuserId;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  convertCustomDateFormat(date, sourceFormat, destFormat, languageCode = null) {
    if (languageCode == null) {
      return moment(date, sourceFormat).format(destFormat);
    }
    return date;
  }

  preventNumberInput(event: any) {
    var a = [];
    var k = event.which;

    for (let i = 48; i < 58; i++) a.push(i);

    if (a.indexOf(k) >= 0) event.preventDefault();
  }

  getIpAddress2() {
    return this.http.get("https://api.country.is/");
  }

  toTitleCase(str) {
    if (str != null) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return "N/A";
    }
  }

  getDomainName() {
    return environment.code || "LT";
  }

  handleError(error) {
    let errorMessage = {};

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = { message: error.error.message };
    } else {
      // server-side error
      errorMessage = { status: error.status, message: error.error.message };
    }
    return throwError(errorMessage);
  }

  handleGTMEvents(input) {
    let eventOb = {
      event: input.event || null,
      type: input.type || null,
      count: input.count || null,
      lang: localStorage.getItem("_lang")
        ? JSON.parse(localStorage.getItem("_lang")).name
        : null,
      origin: input.origin || null,
      destination: input.destination || null,
      location: input.location || null,
      title: input.title || null,
      email: input.email || null,
      ecommerce: input.ecommerce || null,
    };

    window["dataLayer"].push(eventOb);
  }

  parseIntItem(num) {
    if (num > 0) {
      return Math.floor(num);
    }
    return 0;
  }

  parseIntItemPlusOne(num) {
    if (num > 0) {
      return Math.ceil(num);
    }
    return 0;
  }

  checkRateInteger(num) {
    if (num > 0) {
      return !Number.isInteger(num);
    }
    return 0;
  }

  getSearchCombination(combinations) {
    return combinations.map((combination) => ({
      rooms: 1,
      adults: combination.a,
      children: combination.c.length,
      children_age: combination.c.length > 0 ? combination.c : undefined,
    }));
  }

  formatChildAdults = (rooms) => {
    return rooms.map((booking) => ({
      a: booking.adults,
      c: booking.children,
    }));
  };

  formatChildAdultsReverse = (rooms) => {
    return rooms.map((booking) => ({
      adults: booking.a,
      children: booking.c,
    }));
  };

  getParamOfUniqueRoom(room) {
    let regex = /param_(\d+)/;
    let match = room?.match(regex);
    return match?.[1] ? Number(match[1]) : null;
  }

  checktypeof(item) {
    return typeof item;
  }

  continueToCart() {
    this.router.navigate([this.code + "cart/checkout"]);
  }

  moveHotelToFront(arr: any, hotelId: number): number[] {
    return arr.sort((a, b) =>
      a?.details?.id == hotelId ? -1 : b?.details?.id == hotelId ? 1 : 0
    );
  }

  sortByRelevancy(data, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        var x = a?.details?.exclusiveDeal || 0;
        var y = b?.details?.exclusiveDeal || 0;
        if (x === y) {
          var xr = a?.details?.hotelReviews?.[0]?.rate;
          var xy = b?.details?.hotelReviews?.[0]?.rate;
          if (xr == xy) {
            const hotelASellingOb = getSellingPriceForHotel(a?.cug, a?.non_cug);
            const hotelBSellingOb = getSellingPriceForHotel(b?.cug, b?.non_cug);
            var xp = hotelASellingOb.selling["total"];
            var yp = hotelBSellingOb.selling["total"];
            if (way === "ASC") {
              return yp - xp || x - y;
            } else {
              return xp - yp || y - x;
            }
          } else {
            if (way === "ASC") {
              return xr - xy;
            } else {
              return xy - xr;
            }
          }
        } else {
          if (way === "ASC") {
            return y - x;
          } else {
            return x - y;
          }
        }
      });
    }
  }

  sortPriceJSON(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort((a, b) => {
        const x = a?.hotelSellingRate.selling[key];
        const y = b?.hotelSellingRate.selling[key];
        const direction = way === "ASC" ? 1 : -1;

        if (x < y) {
          return -1 * direction;
        } else if (x > y) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }

  sortByRatings(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        const x = a?.details[key];
        const y = b?.details[key];
        const direction = way === "ASC" ? 1 : -1;
        if (x < y) {
          return -1 * direction;
        } else if (x > y) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }

  sortByHotelName(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        const x = a?.details[key].toLowerCase();
        const y = b?.details[key].toLowerCase();
        const direction = way === "ASC" ? 1 : -1;
        if (x < y) {
          return -1 * direction;
        } else if (x > y) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }

  sortByMembershipPrice(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        const x = a?.hotelSellingRate[key];
        const y = b?.hotelSellingRate[key];
        const direction = way === "ASC" ? 1 : -1;
        if (x < y) {
          return -1 * direction;
        } else if (x > y) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }

  sortByMembershipPerc(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        const x = a?.hotelSellingRate[key];
        const y = b?.hotelSellingRate[key];
        const direction = way === "ASC" ? 1 : -1;
        if (x < y) {
          return -1 * direction;
        } else if (x > y) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }

  formatLongText(data: any) {
    const maxLength = 150;
    const text: string = data ?? ""; // null-coalescing operator not yet supported
    const tokens: string[] = text.split(".");
    let result: string = "";
    let offset: number = 0;
    for (const element of tokens) {
      if (result.length - offset >= maxLength) {
        result += "<br><br>";
        offset = result.length;
      }
      if (element.length > 0) result += `${element}.`;
    }

    const fix = /\d+.\s+\d+/g;
    const matches = result.match(fix);

    if (matches) {
      matches.forEach((match) => {
        result = result.replace(match, match.replace(/\s+/g, ""));
      });
    }

    return result;
  }

  counter(i: any) {
    if (i > 0) {
      i = Math.ceil(i);
      return new Array(i);
    }
    return new Array(0);
  }

  handleAmenitiesExchange(data, isCharged) {
    const specialAmenities = [
      {
        keyword: "air condition",
        name: "Air Conditioned",
        icon: "assets/images/hotel-search/air_conditioned.svg",
      },
      {
        keyword: "parking",
        name: "Parking",
        icon: "assets/images/hotel-search/parking.svg",
      },
      {
        keyword: "spa",
        name: "Spa",
        icon: "assets/images/hotel-search/spa.svg",
      },
      {
        keyword: "gym",
        name: "Gym",
        icon: "assets/images/hotel-search/gym.svg",
      },
      {
        keyword: "pool",
        name: "Swimming pool",
        icon: "assets/images/hotel-search/swimming.svg",
      },
      {
        keyword: "swimming",
        name: "Swimming pool",
        icon: "assets/images/hotel-search/swimming.svg",
      },
      {
        keyword: "restaurant",
        name: "Restaurant",
        icon: "assets/images/hotel-search/restaurant.svg",
      },
    ];

    return data.map((item) => {
      const lowerItem = item.toLowerCase();
      const specialAmenity = specialAmenities.find((a) =>
        lowerItem.includes(a.keyword)
      );

      if (specialAmenity) {
        return {
          name: specialAmenity.name,
          icon: specialAmenity.icon,
          value: "special",
          charged: isCharged,
        };
      } else {
        return {
          name: item,
          icon: "assets/images/hotel-search/generic-service-black.svg",
          value: "common",
          charged: isCharged,
        };
      }
    });
  }

  findMostPopularHotels(listHotels) {
    return listHotels
      .map((hotel) => {
        const validReviews = hotel.details.hotelReviews.filter(
          (review) => review.rate >= 4
        );
        if (validReviews.length === 0) return null;

        const totalPopularity = validReviews.reduce(
          (acc, curr) => acc + curr.rate * curr.reviewCount,
          0
        );
        return { ...hotel, popularityScore: totalPopularity };
      })
      .filter((hotel) => hotel !== null)
      .slice(0, 25);
  }

  calculateHotelAppliedFilter(appliedFilter, filteredArray, hotelPrice) {
    let filters = [];
    appliedFilter.forEach((filter) => {
      switch (filter.name) {
        case "search":
          filteredArray = filteredArray.filter(
            (item) =>
              item.details.name.toLowerCase() ===
              filter.value.trim().toLowerCase()
          );
          filters.push({
            name: filter.value.trim(),
            type: "search",
            convert: false,
            link: filter.value.trim(),
          });
          break;

        case "popular":
          if (filter.value) {
            filteredArray = filteredArray
              .map((hotel) => {
                const validReviews = hotel.details.hotelReviews.filter(
                  (review) => review.rate >= 4
                );
                if (validReviews.length === 0) return null;

                const totalPopularity = validReviews.reduce(
                  (acc, curr) => acc + curr.rate * curr.reviewCount,
                  0
                );
                return { ...hotel, popularityScore: totalPopularity };
              })
              .filter((hotel) => hotel !== null)
              .slice(0, 25);
          }
          filters.push({
            name: "most_popular",
            type: "popular",
            convert: false,
            link: "most_popular",
          });
          break;

        case "price":
          filteredArray = filteredArray.filter(
            (item) =>
              Math.round(item.hotelSellingRate.selling.total) >=
                filter.value?.min &&
              Math.round(item.hotelSellingRate.selling.total) <=
                filter.value?.max
          );
          if (
            Math.round(filter.value?.min) !=
              Math.round(hotelPrice?.price?.min) ||
            Math.round(filter.value?.max) != Math.round(hotelPrice?.price?.max)
          ) {
            filters.push({
              name: `$${Math.round(Number(filter.value?.min))} - $${Math.round(
                filter.value?.max
              )}`,
              type: "price",
              convert: false,
              link: `${Math.round(filter.value?.min)}|${Math.round(
                filter.value?.max
              )}`,
            });
          }
          break;

        case "instalment":
          filteredArray = filteredArray.filter(
            (item) =>
              Math.round(item.hotelSellingRate.secondary_start_price) >=
                filter.value?.min &&
              Math.round(item.hotelSellingRate.secondary_start_price) <=
                filter.value?.max
          );
          if (
            Math.round(filter.value?.min) !=
              Math.round(hotelPrice?.secondary_price?.min) ||
            Math.round(filter.value?.max) !=
              Math.round(hotelPrice?.secondary_price?.max)
          ) {
            filters.push({
              name: `$${Math.round(filter.value?.min)} - $${Math.round(
                filter.value?.max
              )}`,
              type: "instalment",
              convert: false,
              link: `${Math.round(filter.value?.min)}|${Math.round(
                filter.value?.max
              )}`,
            });
          }
          break;

        case "cancellation":
          if (filter.value) {
            filteredArray = filteredArray.filter((item) => item.refundable);
          }
          filters.push({
            name: `free_cancellation`,
            type: "cancellation",
            convert: false,
            link: `free_cancellation`,
          });
          break;

        case "score":
          if (filter.value.length) {
            const minValue = Math.min(...filter.value);
            filteredArray = filteredArray.filter(
              (item) => item.details.hotelReviews?.[0]?.rate * 2 >= minValue
            );
          }
          filter.value.forEach((element) => {
            filters.push({
              name: `${hotelReview[element]}`,
              type: "score",
              convert: false,
              value: element,
              link: filter.value.join("|"),
            });
          });
          break;

        case "amenity":
          if (filter.value.length) {
            filteredArray = filteredArray.filter((item) =>
              filter.value.every((r) =>
                item.details?.amenities?.list?.includes(r)
              )
            );
          }
          filter.value.forEach((element) => {
            filters.push({
              name: element,
              type: "amenity",
              convert: true,
              link: filter.value.join("|"),
            });
          });
          break;

        case "types":
          if (filter.value.length) {
            filteredArray = filteredArray.filter((item) =>
              filter.value.every((boardType) =>
                item.details?.boardList?.some(
                  (itemBoardType) =>
                    itemBoardType.toLowerCase() === boardType.toLowerCase()
                )
              )
            );
          }
          filter.value.forEach((element) => {
            filters.push({
              name: element,
              type: "types",
              convert: true,
              link: filter.value.join("|"),
            });
          });
          break;

        case "establishment":
          if (filter.value.length) {
            filteredArray = filteredArray.filter((item) =>
              filter.value.every((r) =>
                item.details?.establishment?.includes(r)
              )
            );
          }
          filter.value.forEach((element) => {
            filters.push({
              name: element,
              type: "establishment",
              convert: true,
              link: filter.value.join("|"),
            });
          });
          break;

        case "accommodation":
          if (filter.value.length) {
            filteredArray = filteredArray.filter((item) =>
              filter.value.includes(item.details.accommodations)
            );
          }
          filter.value.forEach((element) => {
            filters.push({
              name: element,
              type: "accommodation",
              convert: true,
              link: filter.value.join("|"),
            });
          });
          break;

        case "brand":
          if (filter.value.length) {
            filteredArray = filteredArray.filter((item) =>
              filter.value.some(
                (brand) =>
                  item.details?.hotel_chain?.name?.toLowerCase() ===
                  brand.toLowerCase()
              )
            );
          }
          filter.value.forEach((element) => {
            filters.push({
              name: element,
              type: "brand",
              convert: true,
              link: filter.value.join("|"),
            });
          });
          break;

        case "promos":
          if (filter.value.length) {
            filteredArray = filteredArray.filter((item) =>
              filter.value.every((promotion) =>
                item.details?.promotions?.some(
                  (promo) => promo.toLowerCase() === promotion.toLowerCase()
                )
              )
            );
          }
          filter.value.forEach((element) => {
            filters.push({
              name: element,
              type: "promos",
              convert: true,
              link: filter.value.join("|"),
            });
          });
          break;

        default:
          break;
      }
    });
    return { filteredArray: filteredArray, filters: filters };
  }

  convertUtcToLocal(date: string, isFormatted?: boolean): string {
    const utcDate = isFormatted
      ? new Date(`${date}T07:00:00.000Z`)
      : new Date(date);
    const timeZoneToUse = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const options: Intl.DateTimeFormatOptions = {
      timeZone: timeZoneToUse,
      hour12: false,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const formatter = new Intl.DateTimeFormat("en-US", options).format(utcDate);
    const formattedDate = new Date(formatter);

    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = formattedDate.getDate().toString().padStart(2, "0");
    const hours = formattedDate.getHours().toString().padStart(2, "0");
    const minutes = formattedDate.getMinutes().toString().padStart(2, "0");
    const seconds = formattedDate.getSeconds().toString().padStart(2, "0");
    const milliseconds = utcDate.getMilliseconds().toString().padStart(3, "0");

    const localDateString = isFormatted
      ? `${year}-${month}-${day}`
      : `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    return localDateString;
  }
}
