import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { catchError, shareReplay } from "rxjs/operators";
import { CommonFunction } from "./../_helpers/common-function";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GenericService {
  private days$: Observable<any>;
  private cardItems = new BehaviorSubject([]);
  getCardItems = this.cardItems.asObservable();

  private cardClose = new BehaviorSubject([]);
  getCloseCardReq = this.cardClose.asObservable();

  private submitted = new BehaviorSubject({});
  getSubmitted = this.submitted.asObservable();

  private addNewCardClose = new BehaviorSubject([]);
  getAddNewCardClose = this.addNewCardClose.asObservable();

  private cardCount = new BehaviorSubject([]);
  getCardCount = this.cardCount.asObservable();

  private tripfluencer = new BehaviorSubject([]);
  getTripfluencer = this.tripfluencer.asObservable();

  private tripPopup = new BehaviorSubject([]);
  getTripPopup = this.tripPopup.asObservable();

  private carouselImage = new BehaviorSubject([]);
  getCarouselImages = this.carouselImage.asObservable();

  private showWarning = new BehaviorSubject({});
  getCardAttemptWarning = this.showWarning.asObservable();

  private logout = new BehaviorSubject(false);
  loggedOut = this.logout.asObservable();

  private visibileWarningPopup = new Subject();
  getVisibilityWarningPopup = this.visibileWarningPopup.asObservable();

  private crossSelling = new BehaviorSubject(false);
  getCrossSelling = this.crossSelling.asObservable();

  private appLoading = new BehaviorSubject([]);
  getAppLoading = this.appLoading.asObservable();

  private cartEmpty = new BehaviorSubject(false);
  getCartEmpty = this.cartEmpty.asObservable();

  private cardData = new BehaviorSubject({});
  getCardData = this.cardData.asObservable();

  private languages = new BehaviorSubject([]);
  getLanguages = this.languages.asObservable();

  private flightSetDays = new BehaviorSubject(0);
  getFlightSetDays = this.flightSetDays.asObservable();

  private hotelSetDays = new BehaviorSubject(0);
  getHotelSetDays = this.hotelSetDays.asObservable();

  constructor(
    private http: HttpClient,
    private commonFunction: CommonFunction
  ) {}

  setFlightSetDays(flightSetDays) {
    this.flightSetDays.next(flightSetDays);
  }

  setHotelSetDays(hotelSetDays) {
    this.hotelSetDays.next(hotelSetDays);
  }

  setLanguages(languages) {
    this.languages.next(languages);
  }

  setCardData(cardData) {
    this.cardData.next(cardData);
  }

  setCardItems(cardItems) {
    this.cardItems.next(cardItems);
  }

  closeListCards(item) {
    this.cardClose.next(item);
  }

  setSubmitted(item) {
    this.submitted.next(item);
  }

  closeAddNew(item) {
    this.addNewCardClose.next(item);
  }

  setCartEmpty(item) {
    this.cartEmpty.next(item);
  }

  setCardCount(item) {
    this.cardCount.next(item);
  }

  setTripfluencer(tripfluencer) {
    this.tripfluencer.next(tripfluencer);
  }

  setTripPopup(item) {
    this.tripPopup.next(item);
  }

  setAttemptWarningDetails(data) {
    this.showWarning.next(data);
  }

  setLogoutEvent(data) {
    this.logout.next(data);
  }

  setVisibilityWarningPopup(data: boolean) {
    this.visibileWarningPopup.next(data);
  }

  setCrossSelling(item) {
    this.crossSelling.next(item);
  }

  setAppLoading(data) {
    this.appLoading.next(data);
  }

  // Get All Language
  getAllLanguage() {
    return this.http
      .get(`${environment.apiUrl}v1/language`, this.commonFunction.setHeaders())
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get All Currency
  getCurrencies() {
    return this.http
      .get(`${environment.apiUrl}v1/currency`, this.commonFunction.setHeaders())
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get Module Of Website
  getModules() {
    return this.http
      .get(`${environment.apiUrl}v1/modules`, this.commonFunction.setHeaders())
      .pipe(catchError(this.commonFunction.handleError));
  }

  //Get All Card Of User
  getUserCardList() {
    return this.http
      .get(`${environment.apiUrl}v1/payment`, this.commonFunction.setHeaders())
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Make The Payment Card Default
  makeDefaultCard(data) {
    return this.http.put(
      `${environment.apiUrl}v1/payment/default-card/${data.card_id}`,
      {},
      this.commonFunction.setHeaders()
    );
  }

  // Delete The Payment Card
  deleteCard(id) {
    return this.http
      .delete(
        `${environment.apiUrl}v1/payment/${id}`,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get Instalments
  getInstalemnts(data) {
    return this.http
      .post(
        `${environment.apiUrl}v1/instalment/calculate-instalment`,
        data,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get Instalments Availability
  getInstalemntsAvailability(data) {
    return this.http
      .post(
        `${environment.apiUrl}v1/instalment/instalment-availability`,
        data,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Empty The Cart
  emptyCart() {
    return this.http
      .delete(
        `${environment.apiUrl}v1/cart/empty-cart`,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get All Country
  getCountry() {
    return this.http.get(
      environment.apiUrl + "v1/generic/country",
      this.commonFunction.setHeaders()
    );
  }

  // Get Country's States By Id
  getState(stateId) {
    return this.http.get(
      `${environment.apiUrl}v1/generic/state/${stateId}`,
      this.commonFunction.setHeaders()
    );
  }

  // Get Country's States
  getStates(countryId) {
    return this.http.get(
      `${environment.apiUrl}v1/generic/country/${countryId}/state`,
      this.commonFunction.setHeaders()
    );
  }

  // Get Days Configs for calendar blocking
  getBlockedCalendarDay() {
    if (!this.days$) {
      this.days$ = this.http
        .get<any>(`${environment.apiUrl}v1/payment-configuration/days-config`)
        .pipe(
          shareReplay(1),
          catchError((error) => {
            console.error("Error fetching days", error);
            return of([]);
          })
        );
    }
    return this.days$;
  }

  // Get Active Banner
  getBanner() {
    return this.http.get(
      `${environment.apiUrl}v1/admin-dashboard/list-web-banner`,
      this.commonFunction.setHeaders()
    );
  }

  // Contact Through Laytrip
  createEnquiry(data) {
    return this.http
      .post(
        `${environment.apiUrl}v1/enqiry`,
        data,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get All CMS By Page
  getCmsByPageType(type) {
    const payload = { page_type: type };
    return this.http.get(
      `${environment.apiUrl}v1/cms/${payload.page_type}`,
      this.commonFunction.setHeaders()
    );
  }

  // Get All FAQ
  getFaqData() {
    return this.http.get(
      `${environment.apiUrl}v1/faq`,
      this.commonFunction.setHeaders()
    );
  }

  // Check If User Is Validated
  checkUserValidate(token) {
    return this.http.get(
      `${environment.apiUrl}v1/auth/validate-user/${token}`,
      this.commonFunction.setHeaders()
    );
  }

  // Get Payment Details
  getPaymentDetails() {
    return this.http.get(
      `${environment.apiUrl}v1/payment/details`,
      this.commonFunction.setHeaders()
    );
  }

  // Update Via Apple Logged In User
  updateViaAppleLogin(data) {
    return this.http.put(
      `${environment.apiUrl}v1/auth/update/apple-user`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Update Daily Attempt Of User Card
  updateDailyCardAttempt(attemptCount) {
    let data = {
      attempt: attemptCount,
    };
    return this.http.put(
      `${environment.apiUrl}v1/payment/update-daily-attempt`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Update Attempt Of User Card
  updateCardAttempt() {
    let data = {
      attempt: 1,
    };
    return this.http.put(
      `${environment.apiUrl}v1/payment/update-attempt`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get Carousel Images
  getCarouselImagesList() {
    return this.http.get(
      `${environment.apiUrl}v1/carousel/list`,
      this.commonFunction.setHeaders()
    );
  }

  // Get Meta Tags Of Page
  getMetaTags(pageType) {
    return this.http.get(
      `${environment.apiUrl}v1/seo?url=${pageType}`,
      this.commonFunction.setHeaders()
    );
  }
}
