export enum ActivityPageName {
  LANDING_PAGE = "landing_page",
  ABOUT_US = "about_us",
  FAQS = "faqs",
  PRIVACY_POLICY = "privacy_policy",
  TERMS_AND_CONDITIONS = "terms_and_conditions",
  CONTACT_US = "contact_us",
  MEMBERSHIP_PROGRAM = "membership_program",
  MY_ACCOUNT = "my_account",
  MY_BOOKINGS = "my_bookings",
  FLIGHT_SEARCH = "flight_search",
  HOTEL_SEARCH = "hotel_search",
  HOTEL_DETAILS = "hotel_details",
  CHECKOUT = "checkout",
  CONFIRM_BOOKING = "confirm_booking",
  UNSUBSCRIBE = "unsubscribe",
  NEWSLETTER = "newsletter",
  MANAGE_BOOKING="manage_booking"
}

export enum ActivityDescription {
  REDIRECT_TO_HOME = "Redirected to the landing page successfully",
  CHANGE_LANGUAGE_SUCCESS = "Successfully changed language",
  CHANGE_LANGUAGE_ERROR = "Failed to change language",
  CLICK_SIGN_IN = "Clicked on Sign In",
  CLICK_SIGN_UP = "Clicked on Sign Up",
  SIGN_IN_SUCCESS = "Successfully signed in",
  SIGN_IN_ERROR = "Failed to sign in",
  SIGN_UP_SUCCESS = "Successfully signed up",
  SIGN_UP_ERROR = "Failed to sign up",
  CLICK_SOCIAL_SIGN_IN_UP = "Clicked on social sign in/sign up",
  SOCIAL_SIGN_IN_SUCCESS = "Successfully social signed in/signed up",
  SOCIAL_SIGN_IN_ERROR = "Failed to social sign in/signed up",
  CLICK_FORGOT_PASSWORD = "Clicked on Forgot Password",
  FORGOT_PASSWORD_SUCCESS = "Successfully submitted forgot password request",
  FORGOT_PASSWORD_ERROR = "Failed to sent forgot password request",
  REDIRECT_TO_RESET_PASSWORD = "Redirected to the reset password successfully",
  RESET_PASSWORD_SUCCESS = "Successfully submitted reset password request",
  RESET_PASSWORD_ERROR = "Failed to sent reset password request",
  CLICK_FLIGHT_TAB = "Clicked on Flight Tab",
  CLICK_HOTEL_TAB = "Clicked on Hotel Tab",
  CLICK_MEMBERSHIP_PROGRAM = "Clicked on membership-program button",
  CLICK_SOCIAL_MEDIA = "Clicked on social-media posts",
  NEWSLETTER_SUBSCRIPTION_SUCCESS = "Successfully subscribed to newsletter",
  NEWSLETTER_SUBSCRIPTION_ERROR = "Failed to subscribe newsletter",
  CLICK_ABOUT_US = "Clicked on About Us",
  CLICK_FAQS = "Clicked on FAQs",
  CLICK_PRIVACY_POLICY = "Clicked on Privacy Policy",
  CLICK_TERMS = "Clicked on Terms of Use",
  CLICK_CONTACT_US = "Clicked on Contact Us",
  CLICK_SHARE_BUTTON = "Clicked on Share Button",
  CLICK_MY_ACCOUNT = "Clicked on My Account Link",
  CLICK_MY_BOOKING = "Clicked on My Bookings Link",
  CLICK_LOG_OUT = "Clicked on Log Out",
  CLICK_CART_ICON = "Clicked on Cart Icon Link",
  CLICK_MANAGE_BOOKING = "Clicked on Manage Booking Link",
  FLIGHT_SEARCH_REQUEST = "Successfully submitted flight search request",
  HOTEL_SEARCH_REQUEST = "Successfully submitted hotel search request",
  REDIRECT_TO_MY_ACCOUNT = "Redirected to the my account successfully",
  PROFILE_PICTURE_SUCCESS = "Successfully updated the profile picture",
  PROFILE_PICTURE_ERROR = "Failed to update the profile picture",
  PROFILE_DATA_SUCCESS = "Successfully updated the profile data",
  PROFILE_DATA_ERROR = "Failed to update the profile data",
  CHANGE_PASSWORD_SUCCESS = "Successfully changed the profile password",
  CHANGE_PASSWORD_ERROR = "Failed to change the profile password",
  DELETE_ACCOUNT_SUCCESS = "Successfully deleted the user account",
  DELETE_ACCOUNT_ERROR = "Failed to delete the user account",
  REDIRECT_TO_MY_BOOKING = "Redirected to the my bookings successfully",
  PAID_MISSED_INSTALLMENTS_SUCCESS = "Successfully paid the missed installments",
  PAID_MISSED_INSTALLMENTS_ERROR = "Failed to collect the missed installments",
  REDIRECT_TO_CONTACT_US = "Redirected to the contact-us successfully",
  CONTACT_US_SUCCESS = "Successfully submitted the contact request",
  CONTACT_US_ERROR = "Failed to submit the contact request",
  REDIRECT_TO_MEMBERSHIP = "Redirected to the membership program successfully",
  ADD_NEW_CARD = "Clicked on Add New Card",
  EDIT_EXISTING_CARD = "Clicked on Edit Existing Card",
  DELETE_EXISTING_CARD = "Clicked on Delete Existing Card",
  CLICK_MAKE_DEFAULT_CARD = "Clicked on Make Default Card",
  DEFAULT_CARD_SUCCESS = "Successfully submitted the default card request",
  DEFAULT_CARD_ERROR = "Failed to submit the default card request",
  DELETE_CARD_SUCCESS = "Successfully deleted the card",
  DELETE_CARD_ERROR = "Failed to delete the card",
  ADD_CARD_SUCCESS = "Successfully saved the card details",
  ADD_CARD_ERROR = "Failed to save the card details",
  REDIRECT_TO_FLIGHT_LISTING = "Redirected to the flight list successfully",
  FAILED_TO_REDIRECT_FLIGHT_LISTING = "Failed to redirect to the flight list",
  REDIRECT_TO_HOTEL_LISTING = "Redirected to the hotel list successfully",
  REDIRECT_TO_HOTEL_ROOM_LISTING = "Redirected to the hotel room list successfully",
  FAILED_TO_REDIRECT_HOTEL_ROOM_LISTING = "Failed to redirect to the hotel room list",
  FLIGHT_FILTER_APPLIED = "Successfully applied the flights filter",
  FLIGHT_SORT_APPLIED = "Successfully sorted the flights",
  HOTEL_FILTER_APPLIED = "Successfully applied the hotels filter",
  HOTEL_SORT_APPLIED = "Successfully sorted the hotels",
  ADD_FLIGHT_CART_SUCCESS = "Successfully added the flight to cart",
  ADD_FLIGHT_CART_ERROR = "Failed to add the flight to cart",
  ADD_HOTEL_CART_SUCCESS = "Successfully added the hotel to cart",
  ADD_HOTEL_CART_ERROR = "Failed to add the hotel to cart",
  REDIRECT_TO_CONFIRM_BOOKING = "Redirected to the booking confirmation successfully",
  FAILED_TO_REDIRECT_CONFIRM_BOOKING = "Failed to redirect to the booking confirmation",
  FEEDBACK_SUBMIT_SUCCESS = "Successfully submitted the booking feedback",
  FEEDBACK_SUBMIT_ERROR = "Failed to submit the booking feedback",
  REDIRECT_TO_CHECKOUT = "Redirected to the checkout successfully",
  ADD_TRAVELER_SUCCESS = "Successfully saved the traveler",
  ADD_TRAVELER_ERROR = "Failed to save the traveler",
  UPDATE_TRAVELER_SUCCESS = "Successfully updated the traveler",
  UPDATE_TRAVELER_ERROR = "Failed to update the traveler",
  REMOVE_ITINERARY_SUCCESS = "Successfully removed the itinerary from cart",
  REMOVE_ITINERARY_ERROR = "Failed to remove the itinerary from cart",
  GET_CONTACT_PERSON_DETAILS = "Successfully captured the contact person details for booking",
  ADD_MEMBERSHIP_SUCCESS = "Successfully added the membership",
  ADD_MEMBERSHIP_ERROR = "Failed to add the membership",
  VALIDATE_CART_SUCCESS = "Successfully validated the cart",
  VALIDATE_CART_ERROR = "Failed to validate the cart",
  VALIDATE_GUEST_USER_SUCCESS = "Successfully validated the guest user",
  VALIDATE_GUEST_USER_ERROR = "Failed to validate the guest user",
  VALIDATE_LT_SETTING_SUCCESS = "Successfully validated the lay-trip settings",
  VALIDATE_LT_SETTING_ERROR = "Failed to validate the lay-trip settings",
  VERIFY_AUTH_SUCCESS = "Successfully verified the authenticated the user on checkout",
  VERIFY_AUTH_ERROR = "Failed to authenticate the user on checkout",
  BOOK_CART_SUCCESS = "Successfully booked the cart",
  BOOK_CART_ERROR = "Failed to book the cart",
  BOOKING_SUSPENDED = "Booking is suspended",
  EMPTY_CART = "Cart is empty",
  MEMBERSHIP_CLUB_SELECTED_SUCCESS = "Successfully selected the club membership type",
  MEMBERSHIP_CLUB_SELECTED_ERROR = "Failed to select the club membership type",
  MEMBERSHIP_PRO_SELECTED_SUCCESS = "Successfully selected the pro membership type",
  MEMBERSHIP_PRO_SELECTED_ERROR = "Failed to select the pro membership type",
  COOKIE_ACCEPTED = "User has accepted cookies",
  REDIRECT_TO_PRIVACY_POLICY = "Redirected to the privacy-policy successfully",
  REDIRECT_TO_TERMS = "Redirected to the general-terms-of-use successfully",
  REDIRECT_TO_ABOUT_US = "Redirected to the about-us successfully",
  REDIRECT_TO_FAQ = "Redirected to the faqs successfully",
  OTP_VERIFIED_SUCCESS = "Successfully verified the OTP",
  OTP_VERIFIED_ERROR = "Failed to verify the OTP",
  RE_SEND_OPT_SUCCESS = "Successfully re-sent the OTP",
  RE_SEND_OPT_ERROR = "Failed to re-send the OTP",
  MANAGE_BOOKING_SUCCESS = "Successfully fetched the booking details to manage for the user.",
  MANAGE_BOOKING_ERROR = "Failed to fetch the booking details to manage for the user.",
  REDIRECT_MANAGE_BOOKING_SUCCESS = "Successfully redirected to the manage-booking screen.",
  EARLY_PAYMENT_SUCCESS = 'Successfully paid the early payment',
  EARLY_PAYMENT_ERROR = "Failed to collect the early payment",
}
