import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, takeUntil } from "rxjs/operators";
import { CommonFunction } from "../_helpers/common-function";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { getLoginUserInfo } from "../_helpers/jwt.helper";
import { WebsiteConst } from "../constant/generic.const";
import { HomeService } from "./home.service";
import { of } from "rxjs";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService {
  apiURL = environment.apiUrl;
  private destroyActivity$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private commonFunction: CommonFunction,
    private homeService: HomeService
  ) {}

  // Sign Social In Into Laytrip
  socialLogin(data) {
    return this.http
      .post(
        `${this.apiURL}v1/auth/social-login`,
        data,
        this.commonFunction.setWithoutLoginHeader()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Sign In Into Laytrip
  signin(jsonData) {
    let json = {
      email: jsonData.email,
      password: jsonData.password,
      ip_address: localStorage.getItem("ip_address") || null,
      login_via: localStorage.getItem("device") || null,
    };
    return this.http
      .post(
        `${this.apiURL}v1/auth/signin`,
        json,
        this.commonFunction.setWithoutLoginHeader()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Update The User Details
  updateUser(jsonData) {
    return this.http
      .post(
        `${this.apiURL}v1/auth/update-user`,
        jsonData,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Sign Up Into Laytrip
  signup(formValue) {
    let data = {
      signup_via: "web",
      first_name: formValue.first_name,
      last_name: formValue.last_name,
      email: formValue.email,
      password: formValue.password,
      confirm_password: formValue.confirm_password,
      device_type: 1,
      device_model: navigator.appName,
      device_token: "123abc#$%456",
      app_version: navigator.appVersion,
      os_version: navigator.platform,
      referral_id: formValue.referral_id ? formValue.referral_id : "",
      referred_by: formValue.referred_by ? formValue.referred_by : "",
    };
    return this.http
      .post(
        `${this.apiURL}v1/auth/signup`,
        data,
        this.commonFunction.setWithoutLoginHeader()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Verify The OTP
  verifyOtp(data) {
    return this.http
      .patch(
        `${this.apiURL}v1/auth/verify-otp`,
        data,
        this.commonFunction.setWithoutLoginHeader()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Resend The OTP
  resendOtp(email) {
    let data = { email: email };
    return this.http
      .patch(
        `${this.apiURL}v1/auth/resend-otp`,
        data,
        this.commonFunction.setWithoutLoginHeader()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Forgot Password Send Email
  forgotPassword(formValue) {
    let data = {
      email:
        typeof formValue.email != "undefined" ? formValue.email : formValue,
    };
    return this.http
      .post(
        `${this.apiURL}v1/auth/forgot-password`,
        data,
        this.commonFunction.setWithoutLoginHeader()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Reset The Password
  resetPassword(data) {
    return this.http.post(
      `${this.apiURL}v1/auth/reset-password`,
      data,
      this.commonFunction.setWithoutLoginHeader()
    );
  }

  // Delete The Latrip User Account
  deleteAccount(isRequireBackupFile) {
    const accessToken = localStorage.getItem("_lay_sess");
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        source_code: this.commonFunction.getDomainName() || "LT",
      },
      body: {
        requireBackupFile: isRequireBackupFile,
      },
    };
    return this.http.delete(`${this.apiURL}v1/user/account/request`, options);
  }

  // Change The User password
  changePassword(data) {
    return this.http.put(
      `${this.apiURL}v1/auth/change-password`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Update User Profile Details
  updateProfile(data) {
    return this.http.put(
      `${this.apiURL}v1/auth/profile`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Update User Profile Image
  updateProfileImage(data) {
    return this.http.put(
      `${this.apiURL}v1/auth/profile/picture`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get User Profile Details
  getProfile() {
    return this.http.get(
      `${this.apiURL}v1/auth/profile/`,
      this.commonFunction.setHeaders()
    );
  }

  // Change Email Preferences Of User
  changePreference(data) {
    return this.http.put(
      `${this.apiURL}v1/auth/preference`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get Email Preferences Of User
  getPreference() {
    return this.http.get(
      `${this.apiURL}v1/auth/preference`,
      this.commonFunction.setHeaders()
    );
  }

  // Subscribe The Newsletter
  subscribeNow(email) {
    const data = { email: email };
    return this.http.post(
      `${this.apiURL}v1/news-letters/subscribe`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Verify The User Email
  verifyEmail(email) {
    return this.http.get(
      `${this.apiURL}v1/auth/verify-email-id?email=${email}`,
      this.commonFunction.setHeaders()
    );
  }

  // Register The Guest User
  registerGuestUser(data) {
    return this.http.post(
      `${this.apiURL}v1/auth/guest-user`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Map Guest User to Logged-In
  mapGuestUser(guestUserId) {
    return this.http.patch(
      `${this.apiURL}v1/cart/map-guest-user/${guestUserId}`,
      {},
      this.commonFunction.setHeaders()
    );
  }

  // Deleted Account Status Of User
  getStatusDeleteAccount() {
    return this.http.get(
      `${this.apiURL}v1/user/status/delete-account`,
      this.commonFunction.setHeaders()
    );
  }

  // UnSubscribe The Laytrip Newsletter
  unSubscribe(data) {
    return this.http.patch(
      `${this.apiURL}v1/user/unsubscribe`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Subscribe The Laytrip Newsletter Again
  reSubscription(data) {
    return this.http.patch(
      `${this.apiURL}v1/user/subscribe`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  changeUserLanguage() {
    return this.http.patch(
      `${this.apiURL}v1/user/update-preference-language`,
      null,
      this.commonFunction.setHeaders()
    );
  }

  getUserLanguage() {
    return this.http.post(
      `${this.apiURL}v1/user/get-preferred-language`,
      null,
      this.commonFunction.setHeaders()
    );
  }

  manuallyTakePayment(data) {
    return this.http.post(
      `${this.apiURL}v1/payment/take-manually`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get supplier module details
  getModuleList(search = "") {
    return this.http.get(
      `${this.apiURL}v1/modules/admin-modules`,
      this.commonFunction.setHeaders()
    );
  }

  // Get payment method
  getPaymentMode() {
    return this.http.get(
      `${this.apiURL}v1/payment/payment-mode`,
      this.commonFunction.setHeaders()
    );
  }

  collectUserActivity(data) {
    if (window.location.hostname !== "localhost") {
      return this.http
      .post(
        `${this.apiURL}v1/activities/user-activity-log`,
        data,
        this.commonFunction.setHeaders()
      )
      .pipe(takeUntil(this.destroyActivity$));
    } else {
      return of(null);
    }
  }

  getUserToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      let user = getLoginUserInfo();

      if (
        (!user.roleId ||
          user.roleId == 7 ||
          environment.code == WebsiteConst.PAGOVOY) &&
        window.location.pathname != "/sson"
      ) {
        let guestId = localStorage.getItem("__gst");

        if (!guestId || environment.code == WebsiteConst.PAGOVOY) {
          guestId = uuidv4();
          localStorage.setItem("__gst", guestId);
        }

        this.registerGuestUser({ guest_id: guestId }).subscribe(
          (result: any) => {
            const isAdminSubAccountLoggedIn =
              localStorage.getItem("__temp_login");
            if (!isAdminSubAccountLoggedIn) {
              localStorage.setItem("_lay_sess", result.accessToken);
              this.homeService.setCheckLoginUser(true);
            }
            resolve();
          },
          (error) => {
            console.error("Token generation failed", error);
            resolve();
          }
        );
      } else {
        resolve();
      }
    });
  }

  ngOnDestroy() {
    this.destroyActivity$.next();
    this.destroyActivity$.complete();
  }
}
