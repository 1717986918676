import { Component, Inject } from "@angular/core";
import { GenericService } from "./shared/services/generic.service";
import { v4 as uuidv4 } from "uuid";
import { UserService } from "./shared/services/user.service";
import { CheckOutService } from "./shared/services/checkout.service";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HomeService } from "./shared/services/home.service";
import { SeoService } from "./shared/services/seo.service";
import { CookieService } from "ngx-cookie";
import { environment } from "src/environments/environment";
import { CartService } from "./shared/services/cart.service";
import { Meta } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { Location, DOCUMENT } from "@angular/common";
import { WebsiteConst } from "./shared/constant/generic.const";
import { getFirstDayOfWeek } from "./shared/_helpers/generic.helper";
import { ActivityPageName } from "./shared/enum/user-activity.enum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  original = window.location.pathname;
  private ipDetailsSubscription: Subscription;
  private combinedSubscription: Subscription;
  userAcceptedLocation: boolean = true;

  constructor(
    private genericService: GenericService,
    private checkOutService: CheckOutService,
    private userService: UserService,
    private router: Router,
    private homeService: HomeService,
    private modalService: NgbModal,
    private seoService: SeoService,
    private cookieService: CookieService,
    private cartService: CartService,
    @Inject(DOCUMENT) private doc,
    private _meta: Meta,
    private location: Location
  ) {}

  ngOnInit() {
    this.homeService.setUserIpDetails(true);
    this.ipDetailsSubscription = this.homeService.getUserIpDetails.subscribe(
      (res: boolean) => {
        if (res) {
          this.getIpAddress();
          this.homeService.setUserIpDetails(false);
        }
      }
    );
    this.fetchLtCountries();
    this.fetchUserDevice();
    this.fetchSEOTitles();
    this.removeOlderCache();
  }

  async getIpAddress() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {
          this.userAcceptedLocation = true;
          this.getUserLocation();
        },
        () => {
          this.userAcceptedLocation = false;
          this.getUserLocation();
        }
      );
    }
  }

  getUserLocation() {
    this.homeService.getIpInfo().subscribe((res: any) => {
      if (res?.ip) {
        this.combinedSubscription = this.homeService
          .getGeoInfo(res.ip)
          .subscribe((data: any) => {
            localStorage.setItem("ip_address", data.geoplugin_request);
            let ipDetails = {
              IPv4: data.geoplugin_request,
              city: data.geoplugin_city,
              country_name: data.geoplugin_countryName,
              country_code: data.geoplugin_countryCode,
              postal: null,
              state: data.geoplugin_regionName,
              latitude: data.geoplugin_latitude,
              longitude: data.geoplugin_longitude,
            };
            this.setIpJSON(ipDetails);
          });
      }
    });
  }

  setIpJSON(res) {
    // Check if the response contains a country code
    if (res?.country_code) {
      const countryCode = res.country_code;

      // Fetch locale data based on the country code
      this.homeService
        .getLocaleByCountryCode(countryCode)
        .subscribe((response: any) => {
          if (response?.length) {
            // Extract the first language code and create the locale code
            const languageCode = Object.keys(
              response[0].languages
            )[0].substring(0, 2);
            const localeCode = `${languageCode}-${countryCode.toUpperCase()}`;

            // Get the preferred first day of the week for the given locale
            const preferredFirstDay = getFirstDayOfWeek(localeCode);

            // Store the location data along with the first day of the week
            this.storeLocationData(res, preferredFirstDay);
          }
        });
    } else {
      // If no country code is available, get the default first day of the week
      const preferredFirstDay = getFirstDayOfWeek(null);

      // Store the location data with the default first day of the week
      this.storeLocationData(res, preferredFirstDay);
    }
  }

  storeLocationData(res, preferredFirstDay) {
    localStorage.setItem(
      "lt_location",
      JSON.stringify({
        ip: res.IPv4 || null,
        city: res.city || null,
        country: res.country_name || null,
        countryCode: res.country_code || null,
        postal: res.postal || null,
        state: res.state || null,
        lat: res.latitude || null,
        long: res.longitude || null,
        firstDayOfWeek: preferredFirstDay,
        userAcceptance: this.userAcceptedLocation,
      })
    );
    // Update location status and loading state
    this.homeService.setFromLocation(true);
    this.homeService.setLoadingDeal(true);
  }

  removeCache() {
    this.cookieService.remove("__cke");
    if (!window.location.href.includes("cart")) {
      localStorage.removeItem("_lay_sess");
      localStorage.removeItem("$crt");
      localStorage.removeItem("lt_location");
      localStorage.removeItem("__temp_login");
      this.cookieService.remove("__cc");
      sessionStorage.removeItem("login_details");
      localStorage.removeItem("daily_attempt_count");
      this.homeService.setCheckLoginUser(true);
      this.cartService.setCartItems([]);
      this.cartService.resetPriceChangePopupDetails();
      this.loginGuestUser();
    }
  }

  loginGuestUser() {
    let uuid = uuidv4();
    localStorage.setItem("__gst", uuid);
    this.userService
      .registerGuestUser({ guest_id: uuid })
      .subscribe((result: any) => {
        const isAdminSubAccountLoggedIn = localStorage.getItem("__temp_login");
        if (!isAdminSubAccountLoggedIn) {
          localStorage.setItem("_lay_sess", result.accessToken);
        }
      });
  }

  removeOlderCache() {
    const cookieCache = this.cookieService.get("__cke");
    const isPlatformLt = environment.code == WebsiteConst.LAYTRIP;
    if (cookieCache || !isPlatformLt) {
      const __cke = JSON.parse(cookieCache);
      if (
        (__cke.value && __cke.value != environment.version) ||
        !isPlatformLt
      ) {
        this.removeCache();
      }
    }
  }

  fetchSEOTitles() {
    this.router.events.subscribe((event) => {
      const currentPath = this.location.path();
      if (event instanceof NavigationEnd) {
        let pageType = "home";

        if (event.url != "/") {
          pageType = this.router.url.split("?")[0];

          if (pageType[0] == "/") {
            pageType = pageType.slice(1);
          }

          if (pageType.includes("book/charge")) {
            pageType = "book/charge";
          }
          if (pageType.includes("cart/confirm")) {
            pageType = "cart/confirm";
          }
          if (pageType.includes("affiliate")) {
            pageType = "hotel/search";
          }
        }
        // Get SEO Meta data from DB
        this.genericService
          .getMetaTags(pageType.toString())
          .subscribe((response: any) => {
            if (response && response.data) {
              this.seoService.updateMeta(response.data);
            }
          });

        if (currentPath !== "/" && environment.code == WebsiteConst.PAGOVOY) {
          this.router.navigate(["/"]);
        }

        const pathMappings = [
          { path: "flight/search", name: ActivityPageName.FLIGHT_SEARCH },
          { path: "hotel/search", name: ActivityPageName.HOTEL_SEARCH },
          { path: "hotel/detail", name: ActivityPageName.HOTEL_DETAILS },
          { path: "about-us", name: ActivityPageName.ABOUT_US },
          { path: "faqs", name: ActivityPageName.FAQS },
          { path: "privacy-policy", name: ActivityPageName.PRIVACY_POLICY },
          {
            path: "terms-and-conditions",
            name: ActivityPageName.TERMS_AND_CONDITIONS,
          },
          { path: "unsubscribe", name: ActivityPageName.UNSUBSCRIBE },
          { path: "contact-us", name: ActivityPageName.CONTACT_US },
          {
            path: "membership-program",
            name: ActivityPageName.MEMBERSHIP_PROGRAM,
          },
          { path: "account/bookings", name: ActivityPageName.MY_BOOKINGS },
          { path: "account", name: ActivityPageName.MY_ACCOUNT },
          { path: "cart/checkout", name: ActivityPageName.CHECKOUT },
          { path: "cart/progress", name: ActivityPageName.CHECKOUT },
          { path: "cart/confirm", name: ActivityPageName.CONFIRM_BOOKING },
          { path: "manage-booking", name: ActivityPageName.MANAGE_BOOKING },
        ];

        for (const mapping of pathMappings) {
          if (currentPath.includes(mapping.path)) {
            localStorage.setItem("currentPath", mapping.name);
          }
        }

        if (currentPath == "/" || currentPath == "") {
          localStorage.setItem("currentPath", ActivityPageName.LANDING_PAGE);
        }

        // close all open modals
        this.modalService.dismissAll();
        /* Update Canonical Url */
        const head = this.doc.getElementsByTagName("head")[0];
        let element: HTMLLinkElement =
          this.doc.querySelector(`link[rel='canonical']`) || null;

        if (element == null) {
          element = this.doc.createElement("link") as HTMLLinkElement;
          head.appendChild(element);
        }

        element.setAttribute("href", this.doc.URL.split("?")[0]);
        element.setAttribute("rel", "canonical");
        element.setAttribute("key", "canonical");

        this._meta.addTag({
          property: "og:url",
          content: this.doc.URL.split("?")[0],
          key: "og:url",
        });

        this._meta.addTag({
          property: "twitter:url",
          content: this.doc.URL.split("?")[0],
          key: "twitter:url",
        });
      }
    });
  }

  fetchUserDevice() {
    if (
      /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      localStorage.setItem("device", "android");
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      localStorage.setItem("device", "ios");
    } else {
      localStorage.setItem("device", "web");
    }
  }

  fetchLtCountries() {
    this.genericService.getCountry().subscribe((res) => {
      this.checkOutService.setCountries(res);
    });
  }

  ngOnDestroy() {
    if (this.combinedSubscription) {
      this.combinedSubscription.unsubscribe();
    }
    if (this.ipDetailsSubscription) {
      this.ipDetailsSubscription.unsubscribe();
    }
  }
}
